function SuspenseContent(){

  const dark = localStorage.getItem("theme") === "dark";
    return(
        <div className={"w-full h-screen " + dark ? "bg-base-100 text-white" : "bg-white text-black" }>
            Loading...
        </div>
    )
}

export default SuspenseContent
