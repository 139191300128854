export type DashboardConfiguration = {
  loggedIn?: boolean;
  imprinthasvalue: boolean;
  privacyhasvalue: boolean;
  startupclientid: number;
  globalidmode: number;
  globalidmodetablehead: string;
  guiconfigcontent: string;
  guiconfig: GuiConfig;
  clients: Client[];
  navitems: NavItem[];
  upcomingmeetings: Meeting[];
  pastmeetings: Meeting[];
  recentchangesitems?: RecentChange[]; // Neues Feld hinzugefügt
}

export type GuiConfig = {
  bannerUrl: string,
  logoUrl: string,
  name: string,
  standardcolor: StandardThemeType,
  theme: Theme,
  stylesheetUrl: string,
  pageTitle: string,
}

export type Theme = {
  primaryColor: string,
  primaryColor_lighter: string,
  dark_primaryColor: string,
  dark_primaryColor_darker: string,
  secondaryColor: string,
}

export enum StandardThemeType {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  ORANGE = 'orange',
  PETROL = 'petrol',
  GREY = 'grey',
  BROWN = 'brown',
  PURPLE = 'purple',
}

export const StandardThemeDefinition: { type: StandardThemeType, theme: Theme }[] = [
  {
    "type": StandardThemeType.BLUE,
    "theme": {
      primaryColor: "#548AC9",
      primaryColor_lighter: "#EEF3FA",
      dark_primaryColor: "#548AC9",
      dark_primaryColor_darker: "#548AC9",
      secondaryColor: "#548AC9",
    }
  },
  {
    "type": StandardThemeType.GREEN,
    "theme": {
      primaryColor: "#25A028",
      primaryColor_lighter: "#EBF7EC",
      dark_primaryColor: "#25A028",
      dark_primaryColor_darker: "#25A028",
      secondaryColor: "#25A028",
    }
  },
  {
    "type": StandardThemeType.RED,
    "theme": {
      primaryColor: "#8A0100",
      primaryColor_lighter: "#F3E6E6",
      dark_primaryColor: "#8A0100",
      dark_primaryColor_darker: "#8A0100",
      secondaryColor: "#8A0100",
    }
  },
  {
    "type": StandardThemeType.ORANGE,
    "theme": {
      primaryColor: "#E87B16",
      primaryColor_lighter: "#FDF2E8",
      dark_primaryColor: "#E87B16",
      dark_primaryColor_darker: "#E87B16",
      secondaryColor: "#E87B16",
    }
  },
  {
    "type": StandardThemeType.PETROL,
    "theme": {
      primaryColor: "#02A9B4",
      primaryColor_lighter: "#E6F5F7",
      dark_primaryColor: "#02A9B4",
      dark_primaryColor_darker: "#02A9B4",
      secondaryColor: "#02A9B4",
    }
  },
  {
    "type": StandardThemeType.GREY,
    "theme": {
      primaryColor: "#474B4C",
      primaryColor_lighter: "#EDEDED",
      dark_primaryColor: "#474B4C",
      dark_primaryColor_darker: "#474B4C",
      secondaryColor: "#474B4C",
    }
  },
  {
    "type": StandardThemeType.BROWN,
    "theme": {
      primaryColor: "#9A8771",
      primaryColor_lighter: "#F5F3F1",
      dark_primaryColor: "#9A8771",
      dark_primaryColor_darker: "#9A8771",
      secondaryColor: "#9A8771",
    }
  },
  {
    "type": StandardThemeType.PURPLE,
    "theme": {
      primaryColor: "#63476C",
      primaryColor_lighter: "#EEEDF0",
      dark_primaryColor: "#63476C",
      dark_primaryColor_darker: "#63476C",
      secondaryColor: "#63476C",
    }
  }
]

export const getThemeByThemeType = (type: StandardThemeType): Theme => {
  const defaultTheme = StandardThemeDefinition.find(themeDef => themeDef.type === StandardThemeType.BLUE)?.theme!;
  const themeDefinition = StandardThemeDefinition.find(themeDef => themeDef.type === type);
  return themeDefinition?.theme || defaultTheme;
}

export type Client = {
  id: number;
  name: string;
}

export type NavItem = {
  id: number;
  text: string;
  url: string;
}

export type Meeting = {
  id: number;
  name: string;
  clientname: string;
  meetingdate: string;
  absolutestarttime: string;
  absoluteendtime: string;
  state: number;
  visibility: number;
  documentcount: number;
}

export type RecentChange = { // Neuer Typ hinzugefügt
  clientId: number;
  clientName: string;
  committeeId: number;
  committeeName: string;
  name: string;
  objectId: string;
  objectType: number;
  objectTypeName: string;
  operation: string;
  operationDateTime: string;
}
