import axios from "axios"

export const guestdashboard = async (client) => {
    const {data} = await axios.get(`web/guestdashboard?${client ? "client=" + client : ""}`);
    return data;
}

export const navitems = async () => {
    const {data} = await axios.get("web/navitems");
    return data;
}

export const imprinthtml = async () => {
    const {data} = await axios.get("web/imprinthtml");
    return data;
}

export const contacthtml = async () => {
    const {data} = await axios.get("web/contactpagehtml");
    return data;
}

export const privacyhtml = async () => {
    const {data} = await axios.get("web/privacyhtml");
    return data;
}

export const startpagehtml = async () => {
    const {data} = await axios.get("web/startpagehtml");
    return data;
}

export const guestnavitems = async () => {
    const {data} = await axios.get("web/guestnavitems");
    return data;
}

export const gueststartpagehtml = async () => {
    const {data} = await axios.get("web/gueststartpagehtml");
    return data;
}

export const changepassworddescription = async () => {
    const {data} = await axios.get("web/changepassworddescription");
    return data;
}

